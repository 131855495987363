import React from 'react'

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-6 text-left text-gray-500">
        Development of the NEWA 3.0 Spotted Lanternfly model was funded by USDA
        NIFA SCRI grant XXXX-XXXXXXX. The{' '}
        <a
          href="https://agriculture.ny.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          New York State Department of Agriculture and Markets
        </a>{' '}
        also provides ongoing support for this model to the{' '}
        <a
          href="https://nysipm.cornell.edu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          New York State Integrated Pest Management Program
        </a>{' '}
        (NYS IPM) which is part of{' '}
        <a
          href="https://cals.cornell.edu/cornell-cooperative-extension"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cornell Cooperative Extension
        </a>{' '}
        and the{' '}
        <a
          href="https://cals.cornell.edu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cornell University College of Agriculture and Life Sciences
        </a>
        . NYS IPM partners closely with the
        <a
          href="https://www.nrcc.cornell.edu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Northeast Regional Climate Center
        </a>{' '}
        to provide public access to NEWA 3.0 as part of the Cornell University
        land grant mission.
      </p>
    </>
  )
}
