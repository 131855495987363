import { isAfter } from "date-fns";
import React from "react";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { formatDate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./spotted-lanternfly.json";
import Acknowledgments from "./_acknowledgements";
import AdultEmergenceSummaryTable from "./_adultEmergenceSummaryTable";
import BiofixDate from "./_biofixDate";
import EggDevSummaryTable from "./_eggDevSummaryTable";
import Graph from "./_graph";
import InstarSummaryTable from "./_instarSummaryTable";
import ManagementGuide from "./_managementGuide";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";

const visibleElementsArr = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

const sept1 = new Date(new Date().getFullYear(), 8, 1);
const sept15 = new Date(new Date().getFullYear(), 8, 15);

export default function SpottedLanternify() {
  const h1Text = modelData.title;
  const { id, tutorialLink, elements } = modelData;

  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);

  let year = dateOfInterest.year;
  let mmm = 8;
  let defaultDate = new Date(year, mmm, 15);

  let sdate = ``;
  let edate = "now";
  if (dateOfInterest.isCurrentYear) {
    if (isAfter(new Date(), defaultDate)) {
      sdate = `${year}091500`;
    } else {
      defaultDate = new Date(year - 1, mmm, 15);
      sdate = `${year - 1}091500`;
    }
  } else {
    defaultDate = new Date(year - 1, mmm, 15);
    sdate = `${year - 1}091500`;
    edate = `${year}111500`;
  }
  // console.log(sdate, edate)
  const { isLoading, data } = useFetchStationData(station, sdate, edate);
  const [userBiofixDate, setUserBiofixDate] = React.useState(null);

  // SET THE DEFAULT START DATE TO 15 SEP OF SELECTED YEAR
  let formattedDefaultDateformatDate = null;
  if (userBiofixDate === null) {
    formattedDefaultDateformatDate = formatDate(defaultDate);
  } else {
    if (Number(userBiofixDate.slice(0, 4)) === defaultDate.getFullYear()) {
      formattedDefaultDateformatDate = userBiofixDate;
    } else {
      formattedDefaultDateformatDate = formatDate(defaultDate);
    }
  }

  const mData = modelLogic(data, formattedDefaultDateformatDate);

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showResultsTable1, setShowResultsTable1] = React.useState(true);
  const [showResultsTable2, setShowResultsTable2] = React.useState(true);
  const [showResultsTable3, setShowResultsTable3] = React.useState(true);
  const [showResultsGraph, setShowResultsGraph] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [visibleElements, setVisibleElements] =
    React.useState(visibleElementsArr);
  // console.log(visibleElements)

  const isVisible = station && mData;

  let showBiofixDateInput = false;
  if (isAfter(new Date(), sept1)) {
    showBiofixDateInput = true;
  }

  let eggDevDate;
  let instar4Date;
  if (mData) {
    eggDevDate = mData.eggDevDate;
    instar4Date = mData.instar4Date;
  }

  React.useEffect(() => {
    let elems = visibleElementsArr.filter(
      (e) => e.priority === 4 || e.priority === 3
    );

    if (eggDevDate) {
      elems = visibleElementsArr.filter(
        (e) => e.priority === 4 || e.priority === 3 || e.priority === 2
      );
    }

    if (instar4Date) {
      elems = [...visibleElementsArr];
    }

    setVisibleElements(elems);
  }, [eggDevDate, instar4Date]);

  React.useEffect(() => {
    if (!user) return;

    if (Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === id);
      if (model) {
        if (
          Object.keys(model.biofix).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.biofix[dateOfInterest.year]).includes(station.id)
        ) {
          setUserBiofixDate(model.biofix[dateOfInterest.year][station.id]);
        } else {
          setUserBiofixDate(null);
        }
      }
    }
  }, [station, id, user, dateOfInterest]);

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={true}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-lg lg:text-2xl"}
      moreInfo={<MoreInfo station={station} moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable1}
      setShowResultsTable={setShowResultsTable1}
      showResultsTable2={showResultsTable2}
      setShowResultsTable2={setShowResultsTable2}
      showResultsTable3={showResultsTable3}
      setShowResultsTable3={setShowResultsTable3}
      showResultsChart={showResultsGraph}
      setShowResultsChart={setShowResultsGraph}
      tutorialLink={tutorialLink}
      areStationsFiltered={false}
      yearDatePicker={true}
      h1Text={h1Text}
    >
      {!isLoading && isVisible && (
        <div className="px-6 py-6 mb-16 bg-white rounded-lg shadow-lg sm:mb-18 md:mb-20">
          <p>
            <b>
              <i>
                The spotted lanternfly model is updated on a regular basis to
                reflect the most reliable and up-to-date information. But you
                should always confirm these predictions with your own
                observations before making important management decisions.
              </i>
            </b>
          </p>
          <p>
            Enter the estimated or actual date of first egg mass detection.{" "}
            <b>September 15</b> is loaded by default but you may change this at
            any time.
          </p>

          <div className="flex items-center justify-left py-6">
            <BiofixDate
              modelId={id}
              id={"biofix"}
              dbKey="biofix"
              minDate={
                isAfter(new Date(), sept15)
                  ? sept15
                  : new Date(year - 1, mmm, 15)
              }
              maxDate={
                dateOfInterest.isCurrentYear
                  ? new Date()
                  : new Date(year - 1, 10, 30)
              }
              biofix={mData && mData.biofixDate}
              userBiofix={userBiofixDate}
              setUserBiofix={setUserBiofixDate}
              defaultDate={defaultDate}
              label={elements.userInput.label}
              tooltip={`Reset biofix to default value`}
              station={station}
            ></BiofixDate>
          </div>

          {!showBiofixDateInput && userBiofixDate === null && (
            <p className="text-center">{elements.userInput.message}</p>
          )}
        </div>
      )}

      {isVisible && (
        <div className={`mb-16 sm:mb-18 md:mb-20`}>
          <Graph title="Development Status" data={mData.graphData}></Graph>
        </div>
      )}

      {/* Management Guide */}
      {showManagementGuide && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ManagementGuide
            biofixDate={mData.biofixDate}
            managementGuide={elements.managementGuide}
            eggDiapauseDate={mData.eggDiapauseDate}
            eggDevDate={mData.eggDevDate}
            instar1Date={mData.instar1Date}
            instar2Date={mData.instar2Date}
            instar3Date={mData.instar3Date}
            instar4Date={mData.instar4Date}
            completeAdultEmergence={mData.completeAdultEmergence}
          ></ManagementGuide>
        </div>
      )}

      {isVisible && mData.instar4Date && showResultsTable3 && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <AdultEmergenceSummaryTable
            tableData={mData.adultEmergence}
            csvData={mData.adultEmergenceCSV}
          ></AdultEmergenceSummaryTable>
        </div>
      )}

      {isVisible && mData.eggDevDate && showResultsTable2 && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <InstarSummaryTable
            tableData={mData.instar}
            csvData={mData.instarCSV}
          ></InstarSummaryTable>
        </div>
      )}

      {/* Egg Dev Summary Table */}
      {isVisible && showResultsTable1 && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <EggDevSummaryTable
            station={station}
            modelData={modelData}
            tableData={mData.eggDiapauseDev}
            csvData={mData.eggDiapauseDevCSV}
          ></EggDevSummaryTable>
        </div>
      )}
    </ModelLayout>
  );
}
