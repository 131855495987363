import React from 'react'
import { CSVLink } from 'react-csv'
import GlobalStateContext from '../../context/globalStateContext'

const th =
  'px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800 sticky top-0'

const rowBg = (complete) => {
  // if (complete === 'Not Started') return 'level-1'
  if (complete === 'In Progress') return 'level-2'
  if (complete === 'Complete') return 'level-4'
}

export default function AdultEmergenceSummaryTable({ tableData, csvData }) {
  const { dateOfInterest } = React.useContext(GlobalStateContext)

  if (!tableData) return null

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          Adult Emergence and Flight Predictions
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`Adult Emergence Summary.csv`}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex items-center"></div>

        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
          <span className="mb-2 mr-3 font-bold tracking-wider text-gray-700 lg:mb-0">
            Status:
          </span>
          <div className="grid grid-cols-2 gap-1">
            <span className="cell level-2">
              In Progress (&gt;0% &amp; &le;100%)
            </span>
            <span className="cell level-4">Complete (&gt;100%)</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-y-auto align-middle border-b border-gray-200 shadow h-128 lg:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className={th}>
                    <span className="uppercase mr-2">Date</span>
                    <small>({dateOfInterest.year})</small>
                  </th>
                  <th className={`${th}`}>
                    <span className="uppercase">Status</span>
                  </th>
                  <th className={`${th}`}>
                    <span className="uppercase">Emergence</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {[...tableData].reverse().map((day, i) => {
                  const statusBg = rowBg(day.status)
                  return (
                    <tr
                      key={day.serverDate}
                      className={
                        i === 2 ? `font-extrabold text-center` : `text-center`
                      }
                    >
                      <td className={`td h-14`}>
                        <span className={`flex flex-col items-center cell`}>
                          {day.serverDate}
                          <small>{day.isForecast ? 'Forecast' : ''}</small>
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell ${statusBg}`}>{day.status}</span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell`}>{day.emergence}%</span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
