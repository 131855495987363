import React from 'react'

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Keena, M.A. and Nielsen, A.L. 2021. Comparison of the Hatch of Newly
        Laid Lycorma delicatula (Hemiptera: Fulgoridae) Eggs From the United
        States After Exposure to Different Temperatures and Durations of Low
        Temperature. Environ. Entomol. 50: 410-417. doi: 10.1093/ee/nvaa177.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Kreitman, D., Keena, M.A., Nielsen, A.L., and Hamilton, G. 2021. Effects
        of Temperature on Development and Survival of Nymphal Lycorma delicatula
        (Hemiptera: Fulgoridae). Environ. Entomol. 50: 183-191. doi:
        10.1093/ee/nvaa155.
      </p>
    </>
  )
}
